import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Icon, Stroke, Style } from 'ol/style';
import Polyline from 'ol/format/Polyline';

import { map } from './map';
import { api_key } from './api_keys';

const waypoints = document.getElementById("waypoints");
const waypoint_add = document.getElementById("waypoint-add");
const waypoint_remove = document.getElementById("waypoint-remove");
const waypoint_up = document.getElementById("waypoint-up");
const waypoint_down = document.getElementById("waypoint-down");
const route_get = document.getElementById("route-get");
const route_zoom = document.getElementById("route-zoom");
const route_remove = document.getElementById("route-remove");
const route_info = document.getElementById("route-info");
const distance = document.getElementById("distance");
const time = document.getElementById("time");
const coord = document.getElementById("mouseCoord4326ClickedAt");


// Hilfsfunktionen
function listboxMove(listbox, direction) {
	let selIndex = listbox.selectedIndex;
	if (-1 == selIndex) {
		alert("Please select an option to move.");
		return;
	}
	let increment = -1;
	if (direction == 'up')
		increment = -1;
	else
		increment = 1;
	if ((selIndex + increment) < 0 ||
		(selIndex + increment) > (listbox.options.length - 1)) {
		return;
	}
	let selValue = listbox.options[selIndex].value;
	let selText = listbox.options[selIndex].text;
	listbox.options[selIndex].value = listbox.options[selIndex + increment].value
	listbox.options[selIndex].text = listbox.options[selIndex + increment].text
	listbox.options[selIndex + increment].value = selValue;
	listbox.options[selIndex + increment].text = selText;
	listbox.selectedIndex = selIndex + increment;
}

function removeOptions(selectElement) {
	while (selectElement.options.length) {
		selectElement.remove(0);
	}
}

// Routing
let routeLayer;

// Waypoint zur Route hinzufügen
waypoint_add.addEventListener("click", (e) => {
	let option = document.createElement("option");
	option.text = coord.value;
	option.value = coord.value;
	waypoints.add(option);
});

// Waypoint entfernen
waypoint_remove.addEventListener("click", (e) => {
	waypoints.remove(waypoints.selectedIndex);
});

// Waypoint mit Doppelklick entfernen
waypoints.addEventListener("dblclick", (e) => {
	waypoints.remove(waypoints.selectedIndex);
});

// Waypoint up
waypoint_up.addEventListener("click", (e) => {
	listboxMove(waypoints, "up");
});

// Waypoint down
waypoint_down.addEventListener("click", (e) => {
	listboxMove(waypoints, "down");
});

// Route entfernen
route_remove.addEventListener("click", (e) => {
	removeOptions(waypoints);
	distance.textContent = "";
	time.textContent = "";
	map.removeLayer(routeLayer);
});

// Zoom to Route-Layer
route_zoom.addEventListener("click", (e) => {
	let layerExtent = routeLayer.getSource().getExtent();
	//map.getView().fit(layerExtent, map.getSize());
	// padding: top right bottom left
	map.getView().fit(layerExtent, { padding: [40, 420, 10, 20] });
});

// Route berechnen
route_get.addEventListener("click", (e) => {
	//console.log('GET ROUTE');
	map.removeLayer(routeLayer);
	let w = Array();
	for (let i = 0; i < waypoints.options.length; i++) {
		w.push("[" + waypoints.options[i].value + "]");
	}
	let wps = '{"coordinates":[' + w.join(",") + "]}";

	// API: https://github.com/Project-OSRM/osrm-backend/blob/master/docs/http.md
	// http://router.project-osrm.org/route/v1/driving/10.415220,53.605112;10.001972,53.541160
	// oder besser, da nicht reglementiert
	// https://openrouteservice.org/dev/#/api-docs/v2/directions/{profile}/json/post
	// https://api.openrouteservice.org/v2/directions/driving-car?api_key=58d904a497c67e00015b45fc3a041537db9b4f7cbbeb6a4feab7a16a&start=10.00770807,53.35958056&end=9.86256838,53.53950405
	fetch('https://api.openrouteservice.org/v2/directions/driving-car', {
		method: 'POST',
		headers: {
			'Accept': 'application/json, text/plain, */*',
			'Content-Type': 'application/json; charset=utf-8',
			'Authorization': api_key
		},
		body: wps
	})
		.then(response => response.json())
		.then(data => {
			//console.log(data);
			//console.log(data.routes[0].summary.distance);
			//console.log(data.routes[0].summary.duration);
			//console.log(data.routes[0].geometry);

			let polyline = data.routes[0].geometry;

			let route = new Polyline({
				factor: 1e5 // WICHTIG!!!
			}).readGeometry(polyline, {
				featureProjection: 'EPSG:3857',
				dataProjection: 'EPSG:4326'
			});
			//console.log(route);

			let routeCoords = route.getCoordinates();
			let routeLength = routeCoords.length;

			let routeFeature = new Feature({
				type: 'route',
				geometry: route
			});

			let startIcon = new Feature({
				type: 'start',
				geometry: new Point(routeCoords[0])
			});

			let endIcon = new Feature({
				type: 'finish',
				geometry: new Point(routeCoords[routeLength - 1])
			});

			let styles = {
				'route': new Style({
					stroke: new Stroke({
						width: 6, color: [0, 0, 0, 0.8]
					})
				}),
				'start': new Style({
					image: new Icon({
						anchor: [0, 1],
						scale: 0.1,
						src: '../images/flag_green.svg'
					})
				}),
				'finish': new Style({
					image: new Icon({
						anchor: [0, 1],
						scale: 0.1,
						src: '../images/flag_red.svg'
					})
				})
			};

			routeLayer = new VectorLayer({
				visible: true,
				source: new VectorSource({
					features: [routeFeature, startIcon, endIcon]
				}),
				style: (feature) => {
					return styles[feature.get('type')];
				}
			});

			map.addLayer(routeLayer);

			distance.textContent = Math.round(data.routes[0].summary.distance / 100) / 10;
			let ttime = data.routes[0].summary.duration;
			let sek = ttime % 60;
			let min = ((ttime - sek) / 60) % 60;
			let std = (((ttime - sek) / 60) - min) / 60;
			let totalTime = ('00' + std).slice(-2) + ':' + ('00' + min).slice(-2) + ':' + ('00' + sek).slice(-2);
			time.textContent = totalTime;
		})
		.catch(error => {
			//console.log('ERROR');
			//console.log(error);
			route_info.textContent = error;
		});
});

