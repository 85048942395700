import { coords_pointermove, coords_click, coords_moveend, coords_change_resolution } from "./coordinates";
import { stadtrad_click_sqlite } from "./stadtrad_sqlite";


export function map_events(map) {
    // wird der Mauszeiger pber die Karte bewegt, aktualisiert sich ständig die Koordinaten
    map.on('pointermove', event => {
        coords_pointermove(event);
    })

    map.on('click', event => {
        coords_click(event);
        stadtrad_click_sqlite(map, event);
    })

    map.on('moveend', event => {
        coords_moveend(map);
    })

    map.getView().on('change:resolution', event => {
        coords_change_resolution(map);
    })
    coords_change_resolution(map);
}


//'pointermove', 'click'... in der Doku openLayers zu finden