import { Draw, Select } from 'ol/interaction';
import { map } from './map';

const select = new Select({
    layers: [window.layerOrte, window.layerRest]
  }) 
  map.addInteraction(select);
  let fensterOffen = false; // Variable zur Verfolgung des Fensterstatus

select.on("select", (e) => {
    if (fensterOffen) {
      return; // Abbruch, wenn das Fenster geöffnet ist
    }
  
    console.log(e.selected[0].getGeometry().getCoordinates());
  
    let textDiv = document.createElement("div");
    document.body.appendChild(textDiv);
  
    textDiv.style.zIndex = 1000;
    textDiv.style.backgroundColor = "rgba(20,20,20,0.5)";
    textDiv.style.position = "absolute";
    textDiv.style.top = "20%";
    textDiv.style.left = "20%";
    textDiv.style.height = "50%";
    textDiv.style.width = "50%";
  
    let close_button = document.createElement("button");
    textDiv.appendChild(close_button);
    close_button.innerHTML = "Schließen";
    close_button.className = "closeR";
    close_button.addEventListener("click", () => {
      document.body.removeChild(textDiv);
      fensterOffen = false; // Fenster wird geschlossen, Status wird aktualisiert
    });
  
    fensterOffen = true; // Fenster ist geöffnet, Status wird aktualisiert
  
  //--------------------------------------------------------------------------------neu start
  
  // Erstelle einen neuen Container für die Radio-Buttons
  let radioContainer = document.createElement("div");
  radioContainer.id = "radioContainer";
  
  // Erstelle und füge den "nix" Radio-Button hinzu
  let nixRadioElement = document.createElement("input");
  nixRadioElement.type = "radio";
  nixRadioElement.name = "stadtrad";
  nixRadioElement.value = "no operation";
  nixRadioElement.checked = true;
  
  // Erstelle das Label-Element für den "nix" Radio-Button
  let nixRadioLabel = document.createElement("label");
  nixRadioLabel.innerHTML = "Beschreibung";
  
  // Füge den "nix" Radio-Button und das Label dem Radio-Container hinzu
  radioContainer.appendChild(nixRadioElement);
  radioContainer.appendChild(nixRadioLabel);
  
  // Erstelle und füge den "create" Radio-Button hinzu
  let createRadioElement = document.createElement("input");
  createRadioElement.type = "radio"; createRadioElement.name = "stadtrad"; createRadioElement.value = "create";
  
  // Erstelle das Label-Element für den "create" Radio-Button
  let createRadioLabel = document.createElement("label");
  createRadioLabel.innerHTML = "Hinzufügen";
  
  // Füge den "create" Radio-Button und das Label dem geöffneten Fenster hinzu
  radioContainer.appendChild(createRadioElement);
  radioContainer.appendChild(createRadioLabel);
  
  // Erstelle und füge den "update" Radio-Button hinzu
  let updateRadioElement = document.createElement("input");
  updateRadioElement.type = "radio"; updateRadioElement.name = "stadtrad"; updateRadioElement.value = "update";
  
  // Erstelle das Label-Element für den "update" Radio-Button
  let updateRadioLabel = document.createElement("label");
  updateRadioLabel.innerHTML = "Ändern";
  
  // Füge den "update" Radio-Button und das Label dem geöffneten Fenster hinzu
  radioContainer.appendChild(updateRadioElement);
  radioContainer.appendChild(updateRadioLabel);
  
  // Erstelle und füge den "delete" Radio-Button hinzu
  let deleteRadioElement = document.createElement("input");
  deleteRadioElement.type = "radio"; deleteRadioElement.name = "stadtrad"; deleteRadioElement.value = "delete";
  
  // Erstelle das Label-Element für den "delete" Radio-Button
  let deleteRadioLabel = document.createElement("label");
  deleteRadioLabel.innerHTML = "Löschen";
  
  // Füge den "delete" Radio-Button und das Label dem geöffneten Fenster hinzu
  radioContainer.appendChild(deleteRadioElement);
  radioContainer.appendChild(deleteRadioLabel);
  
  textDiv.appendChild(radioContainer);
  
  // Erstelle das Formular-Element
  let formStadtrad = document.createElement("form");
  formStadtrad.id = "formStadtrad";
  formStadtrad.className = "formStadtrad";
  
  // Erstelle die Label-Elemente für die Formularfelder
  let labelId = document.createElement("label");
  labelId.innerHTML = "ID:";
  let inputId = document.createElement("input");
  inputId.id = "stadtrad_id"; inputId.type = "number"; inputId.min = "1"; inputId.max = "9999"; inputId.size = "15";
  
  let labelStation = document.createElement("label");
  labelStation.innerHTML = "Station:";
  let inputStation = document.createElement("input");
  inputStation.id = "stadtrad_station"; inputStation.type = "text"; inputStation.size = "25"; inputStation.placeholder = "Ort/Straßenkreuzung";
  
  let labelLat = document.createElement("label");
  labelLat.innerHTML = "Latitude:";
  let inputLat = document.createElement("input");
  inputLat.id = "stadtrad_lat"; inputLat.type = "number"; inputLat.min = "-90"; inputLat.max = "90"; inputLat.size = "15";
  
  let labelLon = document.createElement("label");
  labelLon.innerHTML = "Longitude:";
  let inputLon = document.createElement("input");
  inputLon.id = "stadtrad_lon"; inputLon.type = "number"; inputLon.min = "-180"; inputLon.max = "180"; inputLon.size = "15";
  
  // Füge die Label- und Input-Elemente dem Formular hinzu
  formStadtrad.appendChild(labelId);
  formStadtrad.appendChild(inputId);
  formStadtrad.appendChild(document.createElement("br"));
  
  formStadtrad.appendChild(labelStation);
  formStadtrad.appendChild(inputStation);
  formStadtrad.appendChild(document.createElement("br"));
  
  formStadtrad.appendChild(labelLat);
  formStadtrad.appendChild(inputLat);
  formStadtrad.appendChild(document.createElement("br"));
  
  formStadtrad.appendChild(labelLon);
  formStadtrad.appendChild(inputLon);
  formStadtrad.appendChild(document.createElement("br"));
  
  // Füge das Formular dem textDiv hinzu
  textDiv.appendChild(formStadtrad);
  
  // Erstelle eine Funktion, die das Formular basierend auf dem ausgewählten Radio-Button anzeigt oder versteckt
  function toggleFormVisibility() {
    let selectedValue = document.querySelector('input[name="stadtrad"]:checked').value;
  
    if (selectedValue === "create" || selectedValue === "update") {
      formStadtrad.style.display = "block"; // Zeige das Formular
    } else {
      formStadtrad.style.display = "none"; // Verstecke das Formular
    }
  }
  
  // Zugriff auf die Radio-Buttons und füge einen Event Listener hinzu
  let radioButtons = document.querySelectorAll('input[name="stadtrad"]');
  radioButtons.forEach(function(radioButton) {
    radioButton.addEventListener("change", toggleFormVisibility);
  });
  
  
  //---------------------------------------------------------------------------------neu ende
  
  });
  
  function neuerMarkerRest() {
    const draw = new Draw({
      source: window.sourceOrte,
      type: "Point",
    });
    map.addInteraction(draw);



    draw.on("drawend", (e) => {
      console.log(e.feature.getGeometry().getCoordinates())
      map.removeInteraction(draw);
    });
  };
  window.neuerMarkerRest = neuerMarkerRest; //globale Variable veröffentlichen
  
  function neuerMarkerOrt() {
    //alert('Bitte setze einen Marker!')
    console.log(window.sourceOrte)
    const draw = new Draw({
      source: window.sourceOrte,
      type: "Polygon",
    });
    map.addInteraction(draw);

      // Event-Listener für Tastendruck hinzufügen
    document.addEventListener('keydown', function(event) {
        if (event.key === 'Escape') {
            map.removeInteraction(draw); // Zeichnen abbrechen
            document.removeEventListener('keydown', arguments.callee); // Event-Listener entfernen
        }
    });

    draw.on("drawend", (e) => {
      console.log(e.feature.getGeometry().getCoordinates())
      map.removeInteraction(draw)
    });
  };
  window.neuerMarkerOrt = neuerMarkerOrt; //globale Variable veröffentlichen
  
  //hover button, verlängert den Text
  function anzeigenTextOrt() {
    var text = document.getElementById("textOrt");
    text.style.display = "block";
  }
  window.anzeigenTextOrt = anzeigenTextOrt
  
  function versteckenTextOrt() {
    var text = document.getElementById("textOrt");
    text.style.display = "none";
  }
  window.versteckenTextOrt = versteckenTextOrt
  
  function anzeigenTextRest() {
    var text = document.getElementById("textRest");
    text.style.display = "block";
  }
  window.anzeigenTextRest = anzeigenTextRest
  
  function versteckenTextRest() {
    var text = document.getElementById("textRest");
    text.style.display = "none";
  }
  window.versteckenTextRest = versteckenTextRest