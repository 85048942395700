import { map_events } from './map_events';
import { createLayerPanel } from './layerpanel';
import { Map, View } from 'ol';
import { fromLonLat } from 'ol/proj';
import { BASELAYER } from './baselayer';
import { TRANSPORT } from './transport';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { stadtrad_layer_sqlite_temp, stadtrad_overlay_sqlite } from './stadtrad_sqlite';
import { GeoJSON } from 'ol/format';

const start_center = fromLonLat([10.005, 53.54]);
const start_zoom = 13;

export let map = new Map({
  target: 'map'
});
map.addLayer(BASELAYER);
map.addLayer(TRANSPORT);
map.addLayer(stadtrad_layer_sqlite_temp);
map.addOverlay(stadtrad_overlay_sqlite);


let map_view = new View({
  center: start_center,
  zoom: start_zoom
});
map.setView(map_view);

// Map Events
map_events(map);
// Layer erzeugen
createLayerPanel('baselayer', [BASELAYER, TRANSPORT]);
createLayerPanel('dropdown-content', [BASELAYER, TRANSPORT]);

export function showDropdown() {
  document.getElementById("dropdown").style.display = "block";
}

export function hideDropdown() {
  document.getElementById("dropdown").style.display = "none";
}


