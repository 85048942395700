import Group from 'ol/layer/Group';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import XYZ from 'ol/source/XYZ';
import { Fill, Icon, Stroke, Text, Style, Circle } from 'ol/style';

function resolution2zoom(resolution) {
    let equator_length = 400075016.686;
    let tile_size = 256;
    return (Math.log2(equator_length / (tile_size * resolution), 2))
};

window.sourceOrte = new VectorSource({ wrapX: false });

window.layerOrte = new VectorLayer({
    id: 'orte',
    title: 'Orte',
    visible: true,
    source: window.sourceOrte,
    style: new Style({
        image: new Circle({
            radius: 3,
            fill: new Fill({ color: 'black' }),
            stroke: new Stroke({
                color: 'rgba(50,50,250,0.9)', width: 3
            })
        }),
        fill: new Fill({ color: 'rgba(50,50,250,0.1)' }),
        stroke: new Stroke({
            color: 'rgba(50,50,250,0.9)', width: 3
        })
    })
})

window.sourceRest = new VectorSource({ wrapX: false });

window.layerRest =
    new VectorLayer({
        id: 'rest',
        title: 'Restaurant',
        visible: true,
        source: window.sourceRest,
        style: new Style({
            image: new Circle({
                radius: 3,
                fill: new Fill({ color: 'black' }),
                stroke: new Stroke({
                    color: 'rgba(50,50,250,0.9)', width: 3
                })
            })
        })
    })

// Wird seperat benötigt, weil in stadtrad_qslite.js das eingefügte
// Stadtrad hinzufügen wird.

export const stadtrad_source_sqlite = new VectorSource({
    url: 'http://localhost:8082/get',
    format: new GeoJSON()
})

const stadtrad_layer_sqlite = 
    new VectorLayer({
        id: 'stadtrad_sqlite',
        title: 'Stadtrad',
        visible: true,
        source: stadtrad_source_sqlite,
        style: (feature, resolution) => {
            let idx = feature.get('OBJECTID');
            let icon = '../images/stadtrad_new.svg';
            let zoom = resolution2zoom(resolution);
            let txt = resolution < 15 ? feature.get('station') : '';
            let scale = 0.004 * Math.pow(zoom, 1.5);
            scale = zoom >= 11 ? scale : 0.0;
            let styleCache = {};
            styleCache[idx] = [
                new Style({
                    image: new Icon({
                        anchor: [0.5, 1.0],
                        anchorXUnits: 'fraction',
                        anchorYUnits: 'fraction',
                        opacity: 0.7,
                        scale: scale,
                        src: icon
                    }),
                    text: new Text({
                        font: '16px Calibri,san-serif',
                        offsetY: 12,
                        text: txt,
                        fill: new Fill({
                            color: '#000000'
                        }),
                        stroke: new Stroke({
                            color: '#FFFFFF',
                            width: 3
                        })
                    }),
                    zindex: 999
                })
            ];
            //console.log(styleCache[idx]);
            return styleCache[idx];
        }
    })

const TRANSPORT = new Group({
        id: 'transport',
        title: 'Verkehr',
        layers: [
            new TileLayer({
                id: 'openseamap',
                title: 'OpenSeaMap',
                visible: false,
                source: new XYZ({
                    url: 'http://tiles.openseamap.org/seamark/{z}/{x}/{y}.png'
                })
            }),
            window.layerOrte,  //Layer, wird oben deklariert, damit man auf ihn zugreifen kann
            window.layerRest,
            new VectorLayer({
                id: 'usar',
                title: 'U|S|A|R',
                visible: false,
                source: new VectorSource({
                    url: '../data/hvvstationen.json',
                    format: new GeoJSON()
                }),
                style: (feature, resolution) => {
                    let idx = feature.get('OBJECTID');
                    let icon = '../images/' + feature.get('ART') + '.png';
                    let txt = resolution < 10 ? feature.get('HALTESTELLE') : '';
                    let styleCache = {};
                    styleCache[idx] = [
                        new Style({
                            image: new Icon({
                                anchor: [0.5, 15],
                                anchorXUnits: 'fraction',
                                anchorYUnits: 'pixels',
                                opacity: 0.7,
                                src: icon
                            }),
                            text: new Text({
                                font: '16px Calibri,san-serif',
                                text: txt,
                                fill: new Fill({
                                    color: '#000000'
                                }),
                                stroke: new Stroke({
                                    color: '#FFFFFF',
                                    width: 3
                                })
                            }),
                            zindex: 999
                        })
                    ];
                    console.log(styleCache[idx]);
                    return styleCache[idx];
                }
            }),
            stadtrad_layer_sqlite,
            new VectorLayer({
                id: 'usar_scaled',
                title: 'U|S|A|R (scaled icons)',
                visible: false,
                source: new VectorSource({
                    url: '../data/hvvstationen.json',
                    format: new GeoJSON()
                }),
                style: (feature, resolution) => {
                    let idx = feature.get('OBJECTID');
                    let icon = '../images/' + feature.get('ART') + '.svg';
                    let zoom = resolution2zoom(resolution);
                    let txt = zoom >= 15 ? feature.get('HALTESTELLE') : '';  //station
                    let scale = 0.004 * Math.pow(zoom, 1.5);
                    scale = zoom >= 11 ? scale : 0.0;
                    let styleCache = {};
                    styleCache[idx] = [
                        new Style({
                            image: new Icon({
                                anchor: [0.5, 1.0],
                                anchorXUnits: 'fraction',
                                anchorYUnits: 'fraction',
                                opacity: 0.7,
                                scale: scale,
                                src: icon      //../images/stadtrad.svg
                            }),
                            text: new Text({
                                font: '16px Calibri,san-serif',
                                offsetY: 12,
                                text: txt,
                                fill: new Fill({
                                    color: '#000000'
                                }),
                                stroke: new Stroke({
                                    color: '#FFFFFF',
                                    width: 3
                                })
                            }),
                            zindex: 999
                        })
                    ];
                    console.log(styleCache[idx]);
                    return styleCache[idx];
                }
            })
        ]
    })

export { TRANSPORT };

