import Group from 'ol/layer/Group';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ';
import TileWMS from 'ol/source/TileWMS';


const BASELAYER = new Group({
    'id': 'baselayer',
    'title': 'Basiskarten',
    layers: [
        new TileLayer({
            id: 'OSM',
            title: 'OpenStreetMap',
            type: 'base',
            visible: true,
            source: new OSM()
        }),
        new TileLayer({
            id: 'WikipediaMap',
            title: 'Wikipedia Map',
            type: 'base',
            visible: false,
            source: new XYZ({
                url: 'https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png'
            })
        }),
        new TileLayer({
            id: 'Orthophotos',
            title: 'Orthophotos Hamburg',
            type: 'base',
            visible: false,
            source: new TileWMS({
                url: 'https://geodienste.hamburg.de/HH_WMS_DOP?',
                params: {
                    'LAYERS': 'DOP',
                    'TILED': true
                },
                serverType: 'geoserver'
            })
        })
    ]
});

export { BASELAYER };


